import * as axios from 'axios'
import { from, of } from 'rxjs'
import { catchError, map, timeout } from 'rxjs/operators';
import { Service } from './service';

const instance = axios.create();
instance.defaults.timeout = 10000;

class DocumentsService {
    static baseUrl = `${Service.baseUrl}/docs`;

    static getAll = () => {
        let observable = from(instance.get(`${DocumentsService.baseUrl}/all`, { headers: Service.getAuthHeader() }))
        return observable.pipe(
            timeout(10000),
            catchError(error => {
                console.log(error)
                return of(error.response)
            })
        )
    }

    static createFolder = (data) => {
        let observable = from(instance.post(`${DocumentsService.baseUrl}`, data, { headers: Service.getAuthHeader() }))
        return observable.pipe(
            timeout(10000),
            catchError(error => {
                console.log(error)
                return of(error.response);
            })
        )
    }

    static createFile = (data) => {
        const i = axios.create();
        i.defaults.timeout = 0;
        let observable = from(i.post(`${DocumentsService.baseUrl}`, data, { headers: Service.getAuthHeader() }))
        return observable.pipe(
            catchError(error => {
                console.log(error)
                return of(error.response);
            })
        )
    }

    static update = (data) => {
        let d = JSON.parse(JSON.stringify(data));
        if(data.password.includes('***')) d.password = null;
        let observable = from(instance.put(`${DocumentsService.baseUrl}`, d, { headers: Service.getAuthHeader() }))
        return observable.pipe(
            timeout(10000),
            catchError(error => {
                console.log(error)
                return of(error.response);
            })
        )
    }

    static delete = (id) => {
        let observable = from(instance.delete(`${DocumentsService.baseUrl}/${id}`, { headers: Service.getAuthHeader() }))
        return observable.pipe(
            timeout(10000),
            catchError(error => {
                console.log(error)
                return of(error.response);
            })
        )
    }

}

export { DocumentsService }