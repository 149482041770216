import { createApp } from 'vue'
import App from './layouts/Main'
import router from './router'
import axios from 'axios'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import { Vue3Mq } from "vue3-mq";

import 'primevue/resources/themes/lara-dark-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'
import Tooltip from 'primevue/tooltip';


createApp(App)
    .use(PrimeVue)
    .use(ToastService)
    .use(router)
    .use(Vue3Mq, {
        breakpoints: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1264,
            xl: 1904,
        }
    })
    .directive('tooltip', Tooltip)
    .mount('#app');

window.addEventListener('load', function() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});
window.addEventListener('resize', function() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});