<template>
    <section class="default users">
        <Card style="width: 100%">
            <template #title>
                <div class="card-menu">
                    <Button icon="pi pi-user-plus" label="Nuevo" @click="displayUserModal = true"/>
                </div>
            </template>
            <template v-slot:content>
                <DataTable 
                    :value="users" 
                    :lazy="true" 
                    @sort="onSort($event)" 
                    :loading="loadingUsersTable" 
                    removableSort 
                    responsiveLayout="scroll"
                    ref="dt"
                >
                    <Column field="username" header="Usuario" :sortable="true"></Column>
                    <Column field="name" header="Nombre" :sortable="true"></Column>
                    <Column field="password" header="Contraseña"></Column>
                    <Column field="active" header="Acceso a Admin" class="text-center max-width">
                        <template #body="slotProps">
                            <i :class="'pi pi-' + (slotProps.data.active == 0 ? 'times' : 'check')"></i>
                        </template>
                    </Column>
                    <Column :exportable="false" style="min-width:8rem">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="openEditUserDialog(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteUser(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </template>
            <template #footer>
            </template>
        </Card>
    </section>

    <Dialog :header="isUserModalEdit ? 'Editar Usuario' : 'Agregar Usuario'" v-model:visible="displayUserModal" @hide="initialData=null; disabled=null; isUserModalEdit=false" :breakpoints="{'960px': '90vw'}" :style="{width: '50vw', maxWidth: '450px'}">
        <UserModal v-on:save="saveUser" :disabled="disabled" :initialData="initialData"></UserModal>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="displayUserModal = false" class="p-button-text"/>
            <Button type="submit" label="Guardar" iconPos="right" :loading="savingUser" form="userForm"/>
        </template>
    </Dialog>
    <Dialog v-model:visible="displayDeleteUser" :style="{width: '630px'}" :breakpoints="{'700px': '90vw'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="selectedUser">¿Estás seguro de querer borrar al usuario <b>{{selectedUser.username}}</b>?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="displayDeleteUser = false"/>
                <Button label="Si" icon="pi pi-check" class="p-button-text" :loading="deletingUser" @click="deleteUser" />
            </template>
        </Dialog>
</template>
<script>
import Card from 'primevue/card'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog';
import { UsersService } from '@/services'
import UserModal from '@/modals/users/UserModal'
import { LogOut } from '@/global/actions'

export default {
    components: {
        Card, Button, DataTable, Column, Dialog, UserModal
    },
    data: function() {
        return {
            users: [],
            displayUserModal: false,
            savingUser: false,
            deletingUser: false,
            isUserModalEdit: false,
            loadingUsersTable: true,
            disabled: null,
            initialData: null,
            lazyParams: {},
            displayDeleteUser: false
        }
    },
    methods: {
        saveUser(data) {
            this.savingUser = true;

            let parseResponse = (result) => {
                if(result.status == 200 || result.status == 201) {
                    this.$toast.add({severity:'success', summary: 'Usuarios', detail:'¡Usuario registrado correctamente!', life: 3000})
                    this.displayUserModal = false
                    result.data.password = '******'
                    if(!this.isUserModalEdit)
                        this.users.push(result.data)
                    else {
                        var user = this.users[this.users.findIndex(x=>x.id == result.data.id)];
                        user.active = result.data.active;
                        user.name = result.data.name;
                        user.email = result.data.email;
                    }
                } else {
                    let message = result.data.msg ?? 'Error inesperado'
                    this.$toast.add({severity:'error', summary: 'Usuarios', detail: message, life: 3000})
                }
                this.savingUser = false;
            }

            if(this.isUserModalEdit)
                UsersService.update(data).subscribe(result => {
                    parseResponse(result)
                })
            else
                UsersService.create(data).subscribe(result => {
                    parseResponse(result)
                })
        },
        openEditUserDialog(data) {
            this.initialData = data
            this.disabled = {'username':true}
            this.isUserModalEdit = true
            this.displayUserModal = true
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        loadLazyData() {
            this.loading = true;
            this.users.sort((a,b)=> {
                if(a[this.lazyParams.sortField] > b[this.lazyParams.sortField])
                    return 1 * (this.lazyParams.sortOrder ?? 0)
                else if(a[this.lazyParams.sortField] < b[this.lazyParams.sortField])
                    return -1 * (this.lazyParams.sortOrder ?? 0)
                else
                    return 0
            })
            this.loading = false;
        },
        confirmDeleteUser(selectedUser) {
            this.selectedUser = selectedUser;
            this.displayDeleteUser = true;
        },
        deleteUser(){
            this.deletingUser = true
            UsersService.delete(this.selectedUser).subscribe(result => {
                if(result.status == 200) {
                    this.$toast.add({severity:'success', summary: 'Usuarios', detail:(result.data.msg ?? 'El usuario fue eliminado'), life: 3000})
                    this.users = this.users.filter(x => x.id !== this.selectedUser.id);
                } else {
                    let message = result.data.msg ?? 'Error inesperado'
                    this.$toast.add({severity:'error', summary: 'Usuarios', detail: message, life: 3000})
                }
                this.displayDeleteUser = false
                this.selectedUser = null;
                this.deletingUser = false;
            })
        }
    },
    mounted() {
        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: null,
            sortOrder: null,
            filters: this.filters
        };

        UsersService
            .getAll()
            .subscribe(
                result => {
                    if(result.status === 401 && result.data.code === 'invalid_token') {
                        LogOut(this);
                    } else {
                        this.users = result.data.map(x => {
                            x.password = '******'
                            x.active = x.active == 1 ? true : false
                            return x;
                        });
                        this.loadingUsersTable = false
                    }
                }
            );
    }
}
</script>
<style lang="scss">
    section{
        &.default {
            color: var(--text-color);
        }
        &.users {
            display: flex;
            flex-wrap: wrap;
            padding: 1.75rem;
            width: 100%;
        }
    }
    .p-datatable .p-datatable-tbody > tr > td, .p-datatable .p-datatable-thead > tr > th {
        &.text-center {
            .p-column-title {
                text-align: center;
                width: 100%;
            }
            text-align: center;
        }
        &.max-width {
            max-width: var(--col-mw, 4rem);
        }
    }
    .card-menu {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        width: 100%;
        * > {
            display: flex;
        }
    }
    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>