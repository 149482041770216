<template>
    <section class="default users">
        <Card style="width: 100%">
            <template #title>
                <div class="card-menu">
                    <!-- <Button icon="pi pi-user-plus" label="Nuevo"/> -->
                </div>
            </template>
            <template v-slot:content>
                <b>Próximamente</b>
            </template>
            <template #footer>
            </template>
        </Card>
    </section>
</template>
<script>
import Card from 'primevue/card'
import Tree from 'primevue/tree'
// import Button from 'primevue/button'
import { UsersService } from '@/services'

export default {
    components: {
        Card
    },
    data: function() {
        return {
        }
    },
    methods: {
       
    },
    mounted() {
        
    }
}
</script>
<style lang="scss">
</style>