import * as axios from 'axios'
import { from, of } from 'rxjs'
import { catchError, map, timeout } from 'rxjs/operators';
import { Service } from './service';

const instance = axios.create();
instance.defaults.timeout = 10000;

class UsersService {
    static baseUrl = `${Service.baseUrl}/users`;

    static getAll = () => {
        let observable = from(instance.get(`${UsersService.baseUrl}/all`, { headers: Service.getAuthHeader() }))
        return observable.pipe(
            timeout(10000),
            catchError(error => {
                console.log(error)
                return of(error.response)
            })
        )
    }

    static create = (data) => {
        let observable = from(instance.post(`${UsersService.baseUrl}`, data, { headers: Service.getAuthHeader() }))
        return observable.pipe(
            timeout(10000),
            catchError(error => {
                console.log(error)
                return of(error.response);
            })
        )
    }

    static update = (data) => {
        let d = JSON.parse(JSON.stringify(data));
        if(data.password.includes('***')) d.password = null;
        let observable = from(instance.put(`${UsersService.baseUrl}`, d, { headers: Service.getAuthHeader() }))
        return observable.pipe(
            timeout(10000),
            catchError(error => {
                console.log(error)
                return of(error.response);
            })
        )
    }

    static delete = (data) => {
        let observable = from(instance.delete(`${UsersService.baseUrl}`, { data: {id: data.id}, headers: Service.getAuthHeader() }))
        return observable.pipe(
            timeout(10000),
            catchError(error => {
                console.log(error)
                return of(error.response);
            })
        )
    }

}

export { UsersService }