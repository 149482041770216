<template>
  <Sidebar v-model:visible="visibleLeft" position="right">
    Content
  </Sidebar>

  <Toast />

  <section class="header">
    <div class="logo">
      <img src="https://gibboradio.com/img/logo-white.svg" alt="">
    </div>
    <div class="action-buttons">
      <Button class="p-button-raised p-button-rounded" icon="pi pi-user" type="button" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"/>
      <Menu class="md" id="overlay_menu" ref="menu" :model="menuItems" :popup="true" />
      <!-- <Button class="p-button-raised p-button-rounded" icon="pi pi-ellipsis-h" @click="visibleLeft = true"/> -->
    </div>
  </section>

  <div class="card">
    <TabMenu :model="routes" />
    <router-view></router-view>
  </div>
</template>

<script>
import Sidebar from 'primevue/sidebar'
import Button from 'primevue/button'
import Menu from 'primevue/menu'
import Toast from 'primevue/toast'
import TabMenu from 'primevue/tabmenu'
import { LogOut } from '@/global/actions'

export default {
  components: {
    Button,
    Sidebar,
    Menu,
    Toast,
    TabMenu,
  },
  data: function(){
    return {
      visibleLeft: false,
      menuItems: [
        // {
        //   label: 'Cambiar contraseña',
        //   icon: 'pi pi-lock',
        //   command: () => {
        //       this.$toast.add({severity:'success', summary:'Updated', detail:'Data Updated', life: 3000});
        //     }
        // },
        {
          label: 'Cerrar sesión',
          icon: 'pi pi-sign-out',
          command: () => {
            LogOut(this);
          }
        }
      ],
      routes: [
        { label: 'Usuarios', icon: 'pi pi-users', to: '/dashboard/users'},
        { label: 'Contenido', icon: 'pi pi-desktop', to: '/dashboard/content'},
        { label: 'Documentos', icon: 'pi pi-cloud-upload', to: '/dashboard/documents'}
      ]
    }
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  },
}
</script>

<style lang="scss">
  section.main-container {
    background: var(--surface-a);
  }

  .p-menu.md {
    width: 14rem;
  }

  section.header {
    display: inline-flex;
    white-space: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--surface-d);

    div {
      display: flex;
      margin: 1rem 2rem;
    }

    .logo {
      width: 120px;

      img {
        max-width: 100%;
        height: 100%;
      }
    }

    .action-buttons > *{
      margin: 0 0.5rem;
    }
  }
</style>