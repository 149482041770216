import { Home, Login, Users, Content, Documents } from '@/pages'

export default [
    { path: '/', name: 'home', redirect: { name: 'dashboard' } },
    { path: '/login', name: 'login', component: Login, props: { colors: ['#1b648f','#4a00ea','#0abdc6','#133e7c'] } },
    { 
        path: '/dashboard', 
        name: 'dashboard', 
        component: Home,
        children: [
            { path: '', component: Users },
            { path: 'users', component: Users },
            { path: 'content', component: Content },
            { path: 'documents', component: Documents }
        ]
    }
]