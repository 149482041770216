import { createWebHistory, createRouter } from 'vue-router'
import routes from './routes'
import { globalBeforeAsync } from './guards'

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    return await globalBeforeAsync(to, from, next)
})

export default router;