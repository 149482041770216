<template lang="">
    <div class="modal-form">
        <div class="card">
            <form id="userForm" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <div v-for="field in fields" class="field" :key="field.name">
                    <span :class="getFieldClass(field.type)">
                        <InputText 
                            v-if="(field.type ?? 'text') === 'text'" 
                            :id="field.name" 
                            type="text" 
                            v-model="v$.form[field.name].$model" 
                            :class="{'p-invalid':v$.form[field.name].$invalid && submitted}"
                            :disabled="disabledFields[field.name] ?? false"
                        />
                        <Password 
                            v-if="field.type === 'pass'" 
                            toggleMask
                            v-model="v$.form[field.name].$model"
                            :class="{'p-invalid':v$.form[field.name].$invalid && submitted}"
                            :disabled="disabledFields[field.name] ?? false"
                        />
                        <Checkbox 
                            v-if="field.type === 'check'" 
                            :binary="true"
                            :id="field.name" 
                            v-model="v$.form[field.name].$model"
                            :disabled="disabledFields[field.name] ?? false"
                        />            
                        <label :for="field.name" :class="{'p-error':v$.form[field.name].$invalid && submitted}">{{field.label}}</label>
                    </span>
                    <small v-if="(v$.form[field.name].$invalid && submitted) || v$.form[field.name].$pending.$response" class="p-error">{{getErrorMessage(field.name)}}</small>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { email, required, withI18nMessage } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Checkbox from 'primevue/checkbox'

export default {
    components: {
        InputText, Password, Checkbox
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            form: {
                username: {
                    required
                },
                email: {
                    required,
                    email
                },
                password: {
                    required
                },
                name: {
                    required
                },
                active: {}
            }
        }
    },
    props: {
        initialData:{
            default: null
        },
        disabled: {
            default: {}
        }
    },
    data: function() {
        return {
            form: {
                username: '',
                email: '',
                name: '',
                password: '',
                active: false
            },
            disabledFields: {},
            fields: [
                { 
                    name: "username",
                    label: "Nombre de usuario*"
                },
                { 
                    name: "email" ,
                    label: "Email*"
                },
                { 
                    name: "name" ,
                    label: "Nombre*"
                },
                { 
                    name: "password" ,
                    label: "Contraseña*",
                    type: "pass"
                },
                { 
                    name: "active" ,
                    label: "Acceso a admin",
                    type: "check"
                }
            ],
            errorMessages: {
                email: 'El valor debe ser un email válido',
                required: 'Este campo es requerido'
            },
            submitted: false
        }
    },
    mounted() {
        if(this.initialData)
            this.form = JSON.parse(JSON.stringify(this.initialData))
        if(this.disabled)
            this.disabledFields = this.disabled;
    },
    methods: {
        handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }

            this.$emit('save', this.form)
        },
        getErrorMessage(name) {
            return this.errorMessages[this.v$.form[name].$errors[0]?.$params.type] ?? this.errorMessages.required;
        },
        getFieldClass(type) {
            switch(type) {
                case 'check':
                    return 'field-checkbox'
                default:
                    return 'p-float-label'
            }
        }
    },
}
</script>
<style lang="scss">
.modal-form {
    .card {
        width: 100%;

        form {
            margin-top: 2rem;
        }

        .field {
            margin-bottom: 2.5rem;
        }
        .field-checkbox {
            label {
                margin-left: 1rem
            }
        }
    }

    @media screen and (max-width: 960px) {
        .card {
            width: 100%;
        }
    }
}
</style>