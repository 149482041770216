<template lang="">
    <div class="modal">
        <slot></slot>
        <div class="modal-form">
        <div class="card">
            <form id="folderForm" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <div v-for="field in fields" class="field" :key="field.name">
                    <span :class="getFieldClass(field.type)">
                        <InputText 
                            v-if="(field.type ?? 'text') === 'text'" 
                            :id="field.name" 
                            type="text" 
                            v-model="v$.form[field.name].$model" 
                            :class="{'p-invalid':v$.form[field.name].$invalid && submitted}"
                            :disabled="disabledFields[field.name] ?? false"
                        />
                        <Password 
                            v-if="field.type === 'pass'" 
                            toggleMask
                            v-model="v$.form[field.name].$model"
                            :class="{'p-invalid':v$.form[field.name].$invalid && submitted}"
                            :disabled="disabledFields[field.name] ?? false"
                        />
                        <Checkbox 
                            v-if="field.type === 'check'" 
                            :binary="true"
                            :id="field.name" 
                            v-model="v$.form[field.name].$model"
                            :disabled="disabledFields[field.name] ?? false"
                        />            
                        <label :for="field.name" :class="{'p-error':v$.form[field.name].$invalid && submitted}">{{field.label}}</label>
                    </span>
                    <small v-if="(v$.form[field.name].$invalid && submitted) || v$.form[field.name].$pending.$response" class="p-error">{{getErrorMessage(field.name)}}</small>
                </div>
            </form>
        </div>
    </div>
    </div>
</template>
<script>
import { email, required, withI18nMessage } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Checkbox from 'primevue/checkbox'

export default {
    components: {
        InputText, Password, Checkbox
    },
    setup: () => ({ v$: useVuelidate() }),
    props: {
        initialData:{
            default: null
        },
        disabled: {
            default: {}
        }
    },
    validations() {
        return {
            form: {
                name: {
                    required
                }
            }
        }
    },
    data: function() {
        return {
            form: {
                name: '',
                isPublic: true,
                parentId: null
            },
            disabledFields: {},
            fields: [
                { 
                    name: "name",
                    label: "Nombre de carpeta*"
                }
            ],
            errorMessages: {
                email: 'El valor debe ser un email válido',
                required: 'Este campo es requerido'
            },
            submitted: false
        }
    },
    mounted() {
        if(this.initialData)
            this.form = JSON.parse(JSON.stringify(this.initialData))
        if(this.disabled)
            this.disabledFields = this.disabled;
    },
    methods: {
        handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }

            this.$emit('save', this.form)
        },
        getErrorMessage(name) {
            return this.errorMessages[this.v$.form[name].$errors[0]?.$params.type] ?? this.errorMessages.required;
        },
        getFieldClass(type) {
            switch(type) {
                case 'check':
                    return 'field-checkbox'
                default:
                    return 'p-float-label'
            }
        }
    },
}
</script>
<style lang="scss">
.modal {
    h4 {
        margin-bottom: 0rem;
    }
}
</style>