import * as axios from 'axios'
import { from, of } from 'rxjs'
import { catchError, map, timeout } from 'rxjs/operators';

const instance = axios.create();
instance.defaults.timeout = 10000;

class Service {
    static baseUrl = 'https://gibboradio.com/api/app'// 'http://127.0.0.1:8000/api/app'

    static login = (username, password) => {
        // instance.post(`${Service.baseUrl}/auth`, { un: username, pwd:password }, { timeout: 3000 })
        let observable = from(instance.post(`${Service.baseUrl}/auth`, { un: username, pwd:password }))
        return observable.pipe(
            timeout(10000)
            //catchError(error => console.log(error))
        )
    }

    static me = () => {
        let observable = from(instance.get(`${Service.baseUrl}/me`, {headers: this.getAuthHeader()}))
        return observable.pipe(
            timeout(10000)
            //catchError(error => console.log(error))
        )
    }

    static getAuthHeader = (headers = {}) => {
        headers['Authorization'] = `Bearer ${localStorage.getItem('auth.local.token')}`
        return headers;
    }

}

export { Service }