import axios from "axios"
import { Service } from "../services/service"


const isAuthenticated = async () => {
    try {
        let tokenExpDate = localStorage.getItem('auth.local.exp');
        console.warn(`Token expiring in: ${(tokenExpDate - Date.now()) / 1000 / 60}`)
        if((tokenExpDate ?? 0) - Date.now() < 0) {
            let results = await Service.me().toPromise()
            let expiration = new Date(Date.now() + (+results.data.exp * 1000));
            localStorage.setItem('auth.local.exp', +expiration)

            //if token updated is not expired, continue
            if(expiration - Date.now() > 0) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    } catch(err) {
        return false;
    }
}

const globalBeforeAsync = async (from, to, next) => {
    console.log(from)
    if(from.name !== 'login' && !await isAuthenticated()) 
        next({
            name: 'login', 
            query: {
                redirect: from.path,
            }
        })
    else next()
}

export { 
    globalBeforeAsync,
}