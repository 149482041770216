<template lang="html">
	<DynamicBG :colors="colors"></DynamicBG>
	<div class="login-container">
		<LoginForm v-on:login="login" :data="loginData"></LoginForm>
	</div>
</template>
<script>
import DynamicBG from "@/components/backgrounds/dynamic-bg-01.vue"
import LoginForm from "@/components/login/login-form.vue"
import { Service } from '@/services/service'

export default {
	components: {
		DynamicBG,
		LoginForm
	},
	props: {
		colors: Array
	},
    data: function() {
		return {
			loginData: {
				error: '',
				status: 'none'
			}
		}
    },
	methods: {
		login(data) {
			if(this.loginData.status == 'loginIn')
				return;
			this.loginData.status = 'loginIn';
			Service.login(data.un, data.pwd)
			.subscribe(
				result => {
					if(result.status == 200) {
						this.loginData.status = 'success'
						localStorage.setItem('auth.local.usr', data.un);
						localStorage.setItem('auth.local.token', result.data.token);
						this.$router.push(this.$route.query.redirect || '/');
					}
				},
				error => {
					if(error.message.includes('Network')) {
						this.loginData.error = "Error de conexión con el servidor"
					}
					else {
						if(error.name === 'TimeoutError') {
							this.loginData.error = "La conexión no es estable. Intenta de nuevo más tarde."
						}
						else if(error.response?.status === 400) {
							this.loginData.error = "Usuario o contraseña incorrecta";
						} else {
							this.loginData.error = "Error al iniciar sesión";
						}
					}
				}
			).add(() => {
				setTimeout(() => {
					this.loginData.error = "";
				}, 5000)
				if(this.loginData.error != '') {
					this.loginData.status = 'none'
				}
			});
		}
	},
	mounted() {
		// service.login()
	}
}
</script>
<style>
	.login-container {
		overflow: hidden;
	}
</style>