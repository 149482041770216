<template>
    <section class="default users">
        <Card style="width: 100%">
            <template #title>
                <div class="card-menu">
                    <mq-responsive target="md+" tag="span">
                        <Button 
                            @click="openFolderModal" :disabled="!selectedIsFolderOrRoot" 
                            icon="fal fa-folder-upload" label="Agregar Carpeta"/>
                        <Button 
                            @click="openFileModal" :disabled="!selectedIsFolder" 
                            icon="fal fa-file-plus" label="Agregar Archivo"
                            class="p-button-primary"/>
                        <Button 
                            @click="confirmDialog.display = true" 
                            :disabled="getCurrentDoc == null" 
                            :icon="getCurrentDoc?.isFolder === 0 ? 'fal fa-file-times' : 'fal fa-folder-times'" 
                            label="Borrar"
                            class="p-button-danger" />
                    </mq-responsive>
                    <mq-responsive target="sm-" tag="span">
                        <Button 
                            @click="openFolderModal" :disabled="!selectedIsFolderOrRoot" 
                            icon="fal fa-folder-upload" class="p-button-rounded"/>
                        <Button 
                            @click="openFolderModal" :disabled="!selectedIsFolderOrRoot" 
                            icon="fal fa-folder-upload" class="p-button-rounded"/>
                        <Button 
                            @click="openFileModal" :disabled="!selectedIsFolder" 
                            icon="fal fa-file-plus" class="p-button-rounded" />
                        <Button 
                            @click="confirmDialog.display = true" 
                            :disabled="getCurrentDoc == null" 
                            :icon="getCurrentDoc?.isFolder === 0 ? 'fal fa-file-times' : 'fal fa-folder-times'" 
                            class="p-button-rounded p-button-danger" />
                    </mq-responsive>
                </div>
            </template>
            <template v-slot:content>
                <Tree 
                    :value="nodes" 
                    selectionMode="single" 
                    v-model:selectionKeys="selectedDoc" 
                    :loading="docsLoading"
                    @nodeSelect="onDocSelect" 
                    @nodeUnselect="onDocUnselect"
                    :metaKeySelection="false"
                >
                    <template #default="slotProps">
                        <div class="document-tree-node">
                            <span>{{slotProps.node.label}}</span>
                            <Button  
                                v-if="slotProps.node.isFolder == 1" 
                                v-tooltip.top="'Copiar links de archivos'"
                                class="p-button-rounded p-button-text"
                                style="margin-left: 1rem"
                                icon="far fa-link"
                                @click="getSubLinks(slotProps.node)"
                            />
                            <a  v-if="slotProps.node.isFolder == 0" 
                                class="p-button p-component p-button-icon-only p-button-rounded p-button-text"
                                target="_blank"
                                :href="`${root}/${slotProps.node.path}`"
                            >
                                <span class="far fa-external-link p-button-icon"></span>
                            </a>
                        </div>
                    </template>
                </Tree>
            </template>
            <template #footer>
            </template>
        </Card>
    </section>

    <Dialog :header="fileModal.isEdit ? 'Editar Archivo' : 'Agregar Archivo'" 
            v-model:visible="fileModal.display" 
            @hide="fileModal.default()" 
            :breakpoints="{'960px': '90vw'}" :style="{width: '50vw', maxWidth: '600px'}">
        <FileModal @save="saveFile" :disabled="[]">
            <h4 v-if="getCurrentDoc !== null">{{getCurrentDoc?.name}} ></h4>
        </FileModal>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="fileModal.display = false" class="p-button-text"/>
            <Button type="submit" label="Guardar" iconPos="right" :loading="fileModal.saving" form="fileForm"/>
        </template>
    </Dialog>
    <Dialog :header="folderModal.isEdit ? 'Editar Carpeta' : 'Agregar Carpeta'" 
            v-model:visible="folderModal.display" 
            @hide="folderModal.default()" 
            :breakpoints="{'960px': '90vw'}" :style="{width: '50vw', maxWidth: '450px'}">
        <FolderModal @save="saveFolder" :disabled="[]">
            <h4 v-if="getCurrentDoc !== null">{{getCurrentDoc?.name}} ></h4>
        </FolderModal>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="folderModal.display = false" class="p-button-text"/>
            <Button type="submit" label="Guardar" iconPos="right" :loading="folderModal.saving" form="folderForm"/>
        </template>
    </Dialog>
    <Dialog v-model:visible="confirmDialog.display" :style="{width: '630px'}" :breakpoints="{'700px': '90vw'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="getCurrentDoc">
                ¿Estás seguro de querer eliminar {{getCurrentDoc.isFolder == 1 ? 'la carpeta' : 'el archivo'}} 
                <b>{{getCurrentDoc.name}}</b> 
                {{getCurrentDoc.isFolder == 1 ? 'y todo su contenido?' : ''}}?
            </span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="confirmDialog.default()"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" :loading="confirmDialog.saving" @click="deleteDocument" />
        </template>
    </Dialog>
</template>
<script>
import { MqResponsive } from 'vue3-mq'
import Card from 'primevue/card'
import Button from 'primevue/button'
import Tree from 'primevue/tree'
import Dialog from 'primevue/dialog'
import Inplace from 'primevue/inplace'
import InputText from 'primevue/inputtext'

import { DocumentsService } from '@/services'

import FolderModal from '@/modals/files/FolderModal.vue'
import FileModal from '@/modals/files/FileModal.vue'

import { LogOut, FileServer } from '@/global/actions'

export default {
    inject: ["mq"],
    components: {
        Card, Button, Tree, Dialog, FolderModal, FileModal, MqResponsive
    },
    data: function() {
        return {
            nodes: [],
            selectedDoc: null,
            docsLoading: false,
            getCurrentDoc: null,
            folderModal: {
                isEdit: false,
                display: false,
                saving: false,
                default: () => {
                    this.folderModal.isEdit = false;
                    this.folderModal.display = false;
                }
            },
            fileModal: {
                isEdit: false,
                display: false,
                saving: false,
                default: () => {
                    this.fileModal.isEdit = false;
                    this.fileModal.display = false;
                }
            },
            confirmDialog: {
                display: false,
                saving: false,
                default: () => {
                    this.confirmDialog.display = false;
                }
            }
        }
    },
    methods: {
        getSubLinks(node) {
            var data = '';
            for (let n of node.children) {
                if (n.isFolder == 0) {
                    data += `${this.root}/${n.path}
`;
                }
            }
            this.copyURL(data);
        },
        async copyURL(text) {
            if(text.length == 0) {
                this.$toast.add({severity:'warn', summary: 'Copiar links', detail: "Selecciona una carpeta con archivos.", life: 3000})
                return
            }
            try {
                await navigator.clipboard.writeText(text);
                this.$toast.add({severity:'success', summary: 'Copiar links', detail: "Se han copiado los links al portapapeles.", life: 3000})
            } catch($e) {
                this.$toast.add({severity:'error', summary: 'Copiar links', detail: "No se puede copiar debido a un problema con el navegador.", life: 5000})
            }
        },
        getDocs() {
            this.docsLoading = true;
            DocumentsService.getAll().subscribe( x=> {
                if(x.status == 200){
                    this.nodes = x.data;
                }
                else if(x.status == 401){
                    LogOut(this);
                }
                else {
                    this.$toast.add({severity:'error', summary: 'Documentos', detail: x.data.msg ?? "Error al cargar los documentos", life: 3000})
                }
                this.docsLoading = false;
            })
        },
        onDocSelect(node) {
            this.getCurrentDoc = node;
        },
        onDocUnselect(node) {
            this.getCurrentDoc = null;
        },
        openFolderModal(create = true) {
            if(create) {
                this.folderModal.display = true
            }
        },
        openFileModal() {
            this.fileModal.display = true
        },
        saveFolder(data) {
            this.folderModal.saving = true
            DocumentsService.createFolder({parent_id: data?.parentId ?? this.getCurrentDoc?.id ?? null,name: data.name,isPublic:true,isFolder:true}).subscribe(res => {
                if(res.status == 201) {
                    this.$toast.add({severity:'success', summary: 'Documentos', detail: res.data.msg ?? "Carpeta creada", life: 3000})
                    this.folderModal.display = false
                    this.getDocs();
                } else {
                    this.$toast.add({severity:'error', summary: 'Documentos', detail: res.data.msg ?? "Error al crear la carpeta", life: 3000})
                }
                this.folderModal.saving = false
            })
        },
        saveFile(data) {
            this.fileModal.saving = true
            var request = new FormData();
            request.append('parent_id', data?.parent_id ?? this.getCurrentDoc?.id ?? null);
            if(data?.name)
                request.append('name', data.name)
            request.append('isFolder', false)
            request.append('isPublic', true)
            if(data.file) {
                for(let file of data.file) {
                    request.append('file[]', file);
                }
            }
            DocumentsService.createFile(request).subscribe(res => {
                if(res.status == 201) {
                    this.$toast.add({severity:'success', summary: 'Archivos', detail: res.data.msg ?? `Archivo${data.file?.length > 1 ? 's' : ''} creado${data.file?.length > 1 ? 's' : ''} correctamente`, life: 3000})
                    this.fileModal.display = false
                    this.getDocs();
                } else {
                    this.$toast.add({severity:'error', summary: 'Archivos', detail: res.data.msg ?? `Error al crear ${data.file?.length > 1 ? 'los archivos' : 'el archivo'}`, life: 3000})
                }
                this.fileModal.saving = false
            })
        },
        deleteDocument() {
            this.confirmDialog.saving = true
            DocumentsService.delete(this.getCurrentDoc.id).subscribe(res => {
                if(res.status == 200) {
                    this.$toast.add({severity:res.data.status ?? 'success', summary: 'Documentos', detail: res.data.msg ?? "Documentos eliminados", life: 3000})
                    this.confirmDialog.display = false;
                    this.getDocs()
                } else {
                    this.$toast.add({severity:'error', summary: 'Documentos', detail: res.data.msg ?? "Error al eliminar los documentos", life: 3000})
                }
                this.confirmDialog.saving = false
            })
        }
    },
    computed: {
        selectedIsFolder: function() {
            return this.getCurrentDoc?.isFolder == 1;
        },
        selectedIsFolderOrRoot: function() {
            return this.getCurrentDoc == null || this.getCurrentDoc?.isFolder == 1;
        },
        root: function() {
            return FileServer.getRoot();
        }
    },
    mounted() {
        this.getDocs()
    }
}
</script>
<style lang="scss">
    .card-menu {
        display: flex;
        flex-wrap: wrap;
        .p-button {
            margin-left: 1rem;
        }
    }
    .document-tree-node {
        display: inline-flex;
        align-items: center;

        a.p-button {
            margin-left: 1rem;
            text-decoration: none!important;
        }
    }
</style>