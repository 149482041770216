function LogOut(vueComponent) {
    localStorage.removeItem('auth.local.exp')
    localStorage.removeItem('auth.local.token')
    vueComponent.$router.push({name: 'login'})
}

class FileServer {
    static getRoot() {
        return 'https://gibboradio.com/public/storage'
    }
}

export { LogOut, FileServer }