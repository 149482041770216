<template lang="">
    <div class="modal">
        <slot></slot>
        <div class="modal-form">
        <div class="card">
            <form id="fileForm" @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <div v-for="field in fields" class="field" :key="field.name">
                    <span :class="getFieldClass(field.type)">
                        <InputText 
                            v-if="(field.type ?? 'text') === 'text'" 
                            :id="field.name" 
                            type="text" 
                            v-model="v$.form[field.name].$model" 
                            :class="{'p-invalid':v$.form[field.name].$invalid && submitted}"
                            :disabled="disabledFields[field.name] ?? false"
                        />
                        <Password 
                            v-if="field.type === 'pass'" 
                            toggleMask
                            v-model="v$.form[field.name].$model"
                            :class="{'p-invalid':v$.form[field.name].$invalid && submitted}"
                            :disabled="disabledFields[field.name] ?? false"
                        />
                        <Checkbox 
                            v-if="field.type === 'check'" 
                            :binary="true"
                            :id="field.name" 
                            v-model="v$.form[field.name].$model"
                            :disabled="disabledFields[field.name] ?? false"
                        />
                        <FileUpload 
                            v-if="field.type === 'file'" 
                            v-model="v$.form[field.name].$model"
                            :name="field.name"
                            url="#" 
                            :multiple="true" 
                            cancelLabel="Cancelar"
                            chooseLabel="Elegir"
                            :showUploadButton="false"
                            :maxFileSize="25000000"
                            @select="filesSelected"
                            @clear="filesCleared"
                            @remove="filesRemoved">
                            <template #empty>
                                <p v-if="mq.mdPlus">Arastra archivos aquí para adjuntarlos.</p>
                            </template>
                        </FileUpload>
                        <label v-if="field.label" :for="field.name" :class="{'p-error':v$.form[field.name].$invalid && submitted}">{{field.label}}</label>
                    </span>
                    <small v-if="(v$.form[field.name].$invalid && submitted) || v$.form[field.name].$pending.$response" class="p-error">{{getErrorMessage(field.name)}}</small>
                </div>
            </form>
        </div>
    </div>
    </div>
</template>
<script>
import { email, required, withI18nMessage } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Checkbox from 'primevue/checkbox';
import FileUpload from 'primevue/fileupload';

export default {
    inject: ["mq"],
    components: {
        InputText, Password, Checkbox, FileUpload
    },
    setup: () => ({ v$: useVuelidate() }),
    props: {
        initialData:{
            default: null
        },
        disabled: {
            default: {}
        }
    },
    validations() {
        return {
            form: {
                file: {
                    required
                }
            }
        }
    },
    data: function() {
        return {
            form: {
                parent_id: null,
                file: []
            },
            disabledFields: {},
            fields: [
                { 
                    name: "file",
                    type: 'file'
                }
            ],
            errorMessages: {
                email: 'El valor debe ser un email válido',
                required: 'Este campo es requerido'
            },
            submitted: false
        }
    },
    mounted() {
        if(this.initialData)
            this.form = JSON.parse(JSON.stringify(this.initialData))
        if(this.disabled)
            this.disabledFields = this.disabled;
    },
    methods: {
        handleSubmit(isFormValid) {
            this.submitted = true;
            console.log(this.form)
            if (!isFormValid) {
                return;
            }

            this.$emit('save', this.form)
        },
        getErrorMessage(name) {
            return this.errorMessages[this.v$.form[name].$errors[0]?.$params.type] ?? this.errorMessages.required;
        },
        getFieldClass(type) {
            switch(type) {
                case 'check':
                    return 'field-checkbox'
                default:
                    return 'p-float-label'
            }
        },
        filesSelected(event) {
            this.form.file = event.files
        },
        filesCleared() {
            this.form.file = []
        },
        filesRemoved(event) {
            this.form.file = event.files
        }
    },
}
</script>
<style lang="scss">

</style>