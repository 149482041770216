<template lang="html">
    <section class="bg-container">
        <span v-for="square of squares" v-bind:style="{ 
            color: square.color, 
            top: square.top, 
            left: square.left, 
            width: square.width, 
            height: square.width,
            background: square.background
        }" v-bind:key="square.width"></span>
    </section>
</template>
<script>
export default {
	props: {
		colors: Array
	},
    data: function() {
		return {
			colorArray: this.colors ?? [
				'#711c91',
				'#ea00d9',
				'#0abdc6',
				'#133e7c'
			],
			squares: [],
			squareCreationInterval: null
		}
    },
    methods: {
		createSquares() {
			this.squareCreationInterval = setInterval(() => {
				this.createSquare()
			}, 150)
		},
        createSquare() {
			const size = Math.random() * 50;

			let square = {
				width: 20 + size + 'px',
				height: 20 + size + 'px',

				top: Math.random() * innerHeight + 'px',
				left: Math.random() * innerWidth + 'px',

				background: this.colorArray[Math.floor(Math.random() * this.colorArray.length)],
			}

			this.squares.push(square);

			setTimeout(() => {
				this.squares.shift();
			}, 5000);
		}
    },
	mounted() {
		this.createSquares()
	},
	beforeUnmount() {
		clearInterval(this.squareCreationInterval)
	},
}
</script>
<style scoped>
.bg-container {
    background: #091833;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

span {
  position: absolute;
  pointer-events: none;
  background: #fff;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: scale(1) translateY(-500%) rotate(360deg);
    opacity: 0;
  }
}
</style>