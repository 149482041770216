<template>
<div class="container full-height">
        <!-- <div class="img">

        </div> -->
        <div class="login-content">
            <p class="error" :style="{'display': data.error == '' ? 'none' : ''}" style="color: white">{{data.error}}</p>
            <form @submit.prevent="send">
                <div class="logo">
                    <img src="https://gibboradio.com/img/logo-white.svg" alt="GibboRadio">
                </div>
                <div class="input-div one" v-bind:class="{focus:this.inputs['usrActive']}">
                    <div class="i">
                        <i class="fas fa-user"></i>
                    </div>
                    <div class="div">
                        <h5>Usuario</h5>
                        <input type="text" v-model="user" @focus="addFocusClass('usr')" @blur="removeFocusClass('usr', $event)" class="input" autocomplete="off">
                    </div>
                </div>
                <div class="input-div pass" v-bind:class="{focus:this.inputs['pwdActive']}">
                    <div class="i">
                        <i class="fas fa-lock"></i>
                    </div>
                    <div class="div">
                        <h5>Contraseña</h5>
                        <input ref="pwd" type="password" v-model="pwd" @focus="addFocusClass('pwd')" @blur="removeFocusClass('pwd', $event)" class="input" autocomplete="off">
                    </div>
                </div>
                <!-- <a href="#">Forgot Password?</a> -->
                <button type="submit" class="btn" :disabled="data.status == 'loginIn'">
                    <i v-if="this.data?.status == 'loginIn'" class="fas fa-circle-notch fa-spin"></i>
                    {{ submitValue }}
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data'],
    data: () => {
        return {
            inputs: [{'usrActive': false}, {'pwdActive' :false}],
            user: '',
            pwd: ''
        }
    },
    computed: {
        submitValue: function() {
            switch(this.data?.status) {
                case 'loginIn':
                    return ''
                case 'success':
                    return 'Ingresando...'
                default:
                    return 'Ingresar'
            }
        }
    },
    methods: {
        addFocusClass: function(m) {
            this.inputs[m+'Active'] = true
        },
        removeFocusClass: function(m, e) {
            if(e.target.value.trim() === '')
                this.inputs[m+'Active'] = false;
        },
        send: function(e) {
            e.preventDefault();
            this.$emit('login', {un:this.user, pwd:this.pwd})
        }
    },
    mounted() {
        this.user = localStorage.getItem('auth.local.usr') || '';
        if(this.user !== '') {
            this.inputs.usrActive = true;
            this.$refs.pwd.focus();
        }
        if(this.pwd !== '')
            this.inputs.pwdActive = true;
    }
}
</script>

<style scoped>
input:disabled, button:disabled, .btn:disabled {
    cursor: default!important;
    background: gray!important;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    --input-color: white;
    --h5-color: white;
}
.container {
    display: flex;
    /* grid-template-columns: repeat(1, 1fr);
    grid-gap: 7rem; */
    justify-content: center;
    padding: 0 2rem;
}

.logo {
    margin-bottom: 2rem;
}
.error {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    padding: 0.5rem 1rem;
    background: darkred;
    border-radius: 1rem;
}
/* .img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
} */

.login-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    position: relative;
}

.img img {
    width: 500px;
}

form {
    width: 360px;
}

.login-content img {
    height: 100px;
}

.login-content h2 {
    margin: 15px 0;
    color: #333;
    text-transform: uppercase;
    font-size: 2.9rem;
}

.login-content .input-div {
    position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 1px solid #d9d9d9;
}

.login-content .input-div.one {
    margin-top: 0;
}

.i {
    color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.i i {
    transition: .3s;
}

.input-div>div {
    position: relative;
    height: 45px;
}

.input-div>div>h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--h5-color);
    font-size: 18px;
    transition: .3s;
}

.input-div:before,
.input-div:after {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 0%;
    height: 1px;
    background-color: #38d39f;
    transition: .4s;
}

.input-div:before {
    right: 50%;
}

.input-div:after {
    left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
    width: 50%;
}

.input-div.focus>div>h5 {
    top: -5px;
    font-size: 15px;
}

.input-div.focus>.i>i {
    color: #38d39f;
}

.input-div>div>input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    color: var(--input-color);
}

.input-div.pass {
    margin-bottom: 4px;
}

a {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #999;
    font-size: 0.9rem;
    transition: .3s;
}

a:hover {
    color: #38d39f;
}

.btn {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, cadetblue, #38d39f, cadetblue);
    background-size: 200%;
    font-size: 1.2rem;
    color: #fff;
    text-transform: uppercase;
    margin: 1rem 0;
    cursor: pointer;
    transition: .5s;
}

.btn:hover {
    background-position: right;
}

/* @media screen and (max-width: 1050px) {
    .container {
        grid-gap: 5rem;
    }
} */

@media screen and (max-width: 1000px) {
    form {
        width: 290px;
    }
    .login-content h2 {
        font-size: 2.4rem;
        margin: 8px 0;
    }
    .img img {
        width: 400px;
    }
}

@media screen and (max-width: 900px) {
    .container {
        grid-template-columns: 1fr;
    }
    .img {
        display: none;
    }
    .wave {
        display: none;
    }
    .login-content {
        justify-content: center;
    }
}
</style>